import { FlatList, Image, styled, Text, useDripsyTheme, useSx, View } from 'dripsy';
import React, { useEffect, useMemo, useState } from 'react';
import { ImageStyle, ViewProps } from 'react-native';
import { Skeleton } from '../skeleton';
import {
    hideMoreText,
    validURL,
} from '../../util/helper';
import { MotiPressable } from 'moti/interactions';
import { Link } from '../link';
import { Platform } from 'react-native';
import { useRouter } from 'solito/router';
import { useSelector } from 'react-redux';
import { Button } from 'app/components/button/common-button';
import { Icon } from 'app/components/icon/icon';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { makeUseAxiosHook } from 'app/redux/store';
import Chip from '../chips';
import { I18Text } from 'app/provider/i18-provider';
import { getClientConfig } from '../../redux/memoizedSelectors';
import Dialog from '../dialog';
import DialogTitle from '../dialog/dialog-titel';
import DialogAction from '../dialog/dialog-action';
import { TextArea } from '../text-area';
import { useAlert } from '../../redux/hooks/alert';
import { getUser } from '../../redux/memoizedSelectors';
import { useToast } from 'app/redux/hooks/use-toast';
import { ImageBackground } from 'react-native';
import { ProgressBar } from '../prgress-bar';
// import { config } from 'app/redux/config';

export interface ClassRoomCardProps extends ViewProps {
    id?: string;
    _id?: number;
    author?: string;
    showAuthor?: boolean;
    coverImageStyle?: ImageStyle;
    nameStyle?: any;
    authorStyle?: any;
    viewStyle?: any;
    user?: any;
    name: string;
    cover: string;
    avatar: string;
    totalNumberOfMembers?: string;
    totalNumberOfModerators?: string;
    instituteName?: string;
    slug?: string;
    moderator: any;
    billing: boolean;
    classroomCheck: any;
    centerId: any;
    additionalInfo?: any
    classroomTemplateId?: any
    templateDetails?: any
    iAmAMemberOfClassroom?: boolean
    iAmAModeratorOfClassroom?: boolean
    iAmAnAdminOfClassroom?: boolean
    cardType: string
    onPress?: any;
    totalNumberOfModule?: any;
    onEditClick?: any;
    setArchive?: any;
    archived?: boolean;
    tags?: Array<string>;
}

export const ClassRoomCard = styled(
    ({
        name,
        // cover,
        avatar,
        totalNumberOfMembers,
        totalNumberOfModerators,
        style,
        showAuthor = true,
        author,
        authorStyle,
        instituteName,
        slug,
        moderator,
        billing,
        classroomCheck,
        centerId,
        additionalInfo,
        cardType = 'classroom',
        onPress,
        onEditClick,
        setArchive,
        archived,
        tags = [],
        ...classroomRestData
    }: ClassRoomCardProps) => {
        const sx = useSx();
        const { theme }: any = useDripsyTheme();
        const { push } = useRouter();
        const currentRoute = useCurrentRoute();
        const sendMessage = useToast();
        const [openAddRemarkModal, setRejectRemarkModal] = useState(false);
        const [remarkValue, setRemarkValue] = useState<any>();
        const [overAllProgress, setStudentAppearanceProgress] = useState<any>({
            totalRecord: 0,
            totalCompleted: 0,
        });

        const Alert = useAlert();
        const { user } = useSelector(getUser);

        const { clientConfig: clientConfigData } = useSelector(
            getClientConfig
        );

        const [{ data: centerInfo, loading: centerloading }, getCenter] = makeUseAxiosHook(
            'classroom', { manual: true }
        )(
            {
                url: `/api/v1/classroom-center/${centerId}`,
                method: 'GET',
            },
        );
        const [{ data: remarkData, loading: remarkLoading, error: remarkError },
            rejectRequest,
        ] = makeUseAxiosHook('ttss', {
            manual: true
        })({
            url: '/api/v1/training-reject-requests',
            method: 'POST'
        });

        const [{ data: studentAppearanceData, loading: moduleLoading }, refetch] = makeUseAxiosHook(
            'classroom',
            {
                manual: true,
            }
        )({
            url: '/api/v1/group-progress',
            method: 'GET',
        });


        useEffect(() => {
            refetch({
                params: {
                    classroomId: classroomRestData?._id,
                },
            });
        }, [classroomRestData?._id]);

        useEffect(() => {
            if (studentAppearanceData?.data?.length) {
                let totalCompletedSum = 0;
                let totalRecordSum = 0;

                studentAppearanceData.data.forEach((item: {
                    parentId: any;
                    totalCompleted: number;
                    totalRecord: number;
                }) => {
                    totalCompletedSum += item.totalCompleted;
                    totalRecordSum += item.totalRecord;
                });
                setStudentAppearanceProgress({
                    totalRecord: totalRecordSum,
                    totalCompleted: totalCompletedSum,
                });
            }
        }, [studentAppearanceData]);

        useEffect(() => {
            if (centerId && centerloading) return;
            if (centerId) getCenter();
        }, [centerId]);

        const tabKey: any = useMemo(() => {
            const modules = Object.values(
                classroomRestData?.templateDetails?.modules ??
                clientConfigData?.classroomTabs
            )?.filter((obj) => typeof obj !== 'boolean' && !Array.isArray(obj));

            const activeTabs: any = modules.filter(
                (module: any) =>
                    typeof module !== 'boolean' &&
                    !Array.isArray(module) &&
                    module?.visible
            );

            const structuredCourseModule: any = modules.find(
                (module: any) =>
                    module?.key === 'structured-course' && module?.visible
            );

            const defaultTabKey =
                structuredCourseModule?.key ||
                (activeTabs.length > 0 && activeTabs[0]?.key);
            return defaultTabKey;
        }, [clientConfigData.classroomTabs, classroomRestData.templateDetails]);


        const handleClassroomToBuy = async () => {
            classroomCheck.purchaseType === 'product'
                ? push(`/client-checkout/product/${classroomCheck.productTypeId}`)
                : push(`/client-subscription?continue=${currentRoute}&subscriptionId=${classroomCheck?.productTypeId}`);
        };
        useEffect(() => {
            if (remarkData && !remarkLoading) {
                sendMessage({
                    message: 'Member Rejected Successfully',
                    type: 'success',
                });
            }
            if (remarkError)
                if (
                    (remarkError && !remarkLoading)
                ) {
                    sendMessage({
                        message: remarkError ? remarkError?.response?.data?.message : 'Something went wrong, Please try again',
                        type: 'error',
                    });
                }
        }, [remarkData, remarkError]);

        const center = (centerInfo?.name ? `${centerInfo?.name ? centerInfo.name : ''} ${additionalInfo?.data?.venue_id ? ` - ${additionalInfo.data.venue_id}` : ''}` : instituteName) || '';

        return (
            <View>
                <MotiPressable
                    onPress={() => {
                        if (onPress) {
                            onPress();
                        } else {
                            cardType === 'forum'
                                ? push(
                                    '/forums/[forumSlug]/[pageType]',
                                    `/forums/${slug}/post`
                                ) :
                                cardType === 'moduleManagement' ?
                                    push(
                                        '/module-manage/[moduleSlug]/[pageType]',
                                        `/module-manage/${slug}/module-structure`
                                    )
                                    : push(
                                        '/classrooms/[classroomSlug]/[pageType]',
                                        `/classrooms/${slug}/${tabKey}`
                                    );
                        }

                    }}
                    style={[sx({
                    }), {
                        borderWidth: 0.1,
                        flex: 1,
                        borderRadius: 15,
                        overflow: 'hidden',
                        margin: [2, 3],
                        minHeight: [250],
                        paddingX: [3, 4],
                        paddingY: 3,
                        justifyContent: 'space-between',
                        minWidth: [320, 360],
                        // borderWidth: 1,
                        // borderColor: theme.colors.border,
                        backgroundColor: '$white',
                        shadowColor: '#000',
                        shadowOffset: {
                            width: 0,
                            height: 1,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 2.22,
                        elevation: 3,
                    },
                        style
                    ]}
                    animate={useMemo(
                        () => ({ hovered, pressed }) => {
                            'worklet';
                            return {
                                borderColor: (hovered || pressed) ? theme?.colors['$primary'] : theme?.colors['$white'],
                                borderWidth: 1,
                                borderRadius: 15,
                            };
                        },
                        []
                    )}

                >
                    <ImageBackground
                        source={validURL(avatar) ? { uri: avatar } : require('../../assets/classroomDefault.png')}
                        imageStyle={{
                            resizeMode: 'cover',
                            width: '100%',
                            height: '100%',
                        }}
                        style={sx({
                            flex: 1,
                            borderRadius: 15,
                            overflow: 'hidden',
                            minHeight: [200],
                            justifyContent: 'space-between',
                            minWidth: [320, 360],
                            backgroundColor: '$white',
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 1,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 2.22,
                            elevation: 3,
                        })}
                    >
                        <View
                            style={[sx({
                                flexDirection: 'row',
                                flex: 1,
                                minHeight: 120,
                                zIndex: 99
                            })]}
                        >
                            <View
                                style={sx({
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    flex: 1,
                                    margin: 1
                                })}
                            >
                                <View
                                    style={sx({
                                        flex: 1,
                                        width: '100%'
                                    })}
                                >
                                    <View style={sx({ flexDirection: 'row', justifyContent: 'space-between' , alignItems: 'center' })}>
                                        {onEditClick ? <Button
                                            startIcon={<Icon name="editPen" fill={['$secondary']} />}
                                            onPress={() => {
                                                onEditClick();
                                            }}
                                            variantType="text"
                                            variantColor="secondary"
                                            style={sx({ height: 35, width:35,  backgroundColor: '$white', borderRadius: 25, padding: 10,justifyContent: 'center', alignItems: 'center',
                                                shadowColor: '#000',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 1,
                                                },
                                                shadowOpacity: 0.62,
                                                shadowRadius: 4.55,
                                                borderWidth: 1,
                                                borderColor: '$white',
                                                elevation: 3, })}
                                        /> : <></>}
                                        <View
                                            style={{
                                                flex: 1,
                                                padding: 10,
                                                flexDirection: 'row',
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: tags?.length ? 'space-between' : 'flex-end',
                                            }}
                                        >
                                            {tags?.length ?
                                                <View
                                                    style={sx({ display:'flex',flex:1 ,
                                                    maxHeight:[130,90] ,
                                                    })} >
                                                    <FlatList
                                                        horizontal
                                                        data={tags}
                                                        renderItem={
                                                            (data: any) =>
                                                            (<Chip hideDelete={true} label={data.item} />
                                                            )
                                                        }
                                                        contentContainerStyle={sx({
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            padding: 1,
                                                        })}
                                                        showsHorizontalScrollIndicator={false} 
                                                    />
                                                </View> : <></>}
                                        <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            marginRight: 10,
                                        }}
                                        >
                                         {additionalInfo?.data?.trainingRef ? 
                                            <Button 
                                            variantType="text"
                                            variantColor="secondary"
                                            onHoveredTextColor="#000"
                                            style={[sx({
                                             height: 30,
                                              width: 60, 
                                              marginRight: 2,
                                              backgroundColor: '$primary'
                                            }),
                                            Platform.OS !== 'web' ?
                                                sx({
                                                    height: 40,
                                                    width: 70
                                                }) : {}
                                            ]}>
                                                <I18Text style={sx({
                                                    color: '$white',
                                                    fontSize: 15,
                                                })}
                                                    onPress={() => setRejectRemarkModal(true)}
                                                >Reject</I18Text>
                                            </Button> : <></>}
                                            {onEditClick && setArchive &&
                                                (<Button
                                                    variantType="text"
                                                    variantColor="secondary"
                                                    onHoveredTextColor="#000"
                                                    textColor='#FEFEFE'
                                                    style={sx({ height: 30, width: 80, marginRight: 2 ,backgroundColor: '$primary' })}
                                                    onPress={setArchive} ><I18Text>{archived ? 'Unarchive' : 'Archive'}</I18Text>
                                                    </Button>)}
                                            {onPress ?
                                                <I18Text style={sx({
                                                    backgroundColor: '$white',
                                                    fontSize: 14,
                                                    padding: 2,
                                                    margin:1,
                                                    borderRadius: 5,
                                                    borderWidth: 1,
                                                    borderColor: '$white',
                                                    shadowColor: '#000',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 0,
                                                    },
                                                    shadowOpacity: 0.55,
                                                    shadowRadius: 2.22,
                                                    elevation: 3,
                                                })} label='{module} Module'
                                                    data={{ module: `${classroomRestData?.totalNumberOfModule ?? 0}` }} />
                                                :
                                                <View
                                                    style={sx({
                                                        flexDirection: 'column',  // Keeping column as per initial requirement
                                                        justifyContent: 'space-between',  // Space between the two text boxes 
                                                    })}
                                                >
                                                    <I18Text
                                                        style={sx({
                                                            color: '$white',
                                                            fontSize: [13,15],
                                                            width: [100,130],  // Use 'auto' for responsive width
                                                            minWidth: [100,120],  // Ensure a minimum width
                                                            backgroundColor: '$primary',
                                                            textAlign: 'center',
                                                            borderRadius: 10,
                                                            paddingVertical: 2,  // More appropriate for React Native than 'padding'
                                                            paddingHorizontal: 10,
                                                        })}
                                                        label='{member} Trainer'
                                                        data={{ member: `${totalNumberOfModerators ?? 0}` }}
                                                    />
                                                    <I18Text
                                                        style={sx({
                                                            color: '$white',
                                                            fontSize: [13,15],
                                                            width: [100,130],  // Use 'auto' for responsive width
                                                            minWidth: [100,100],  // Ensure a minimum width
                                                            backgroundColor: '$primary',
                                                            textAlign: 'center',
                                                            borderRadius: 10,
                                                            paddingVertical: 2,  // More appropriate for React Native than 'padding'
                                                            paddingHorizontal: 10,
                                                            marginTop:10
                                                        })}
                                                        label='{member} Participants'
                                                        data={{ member: `${totalNumberOfMembers ?? 0}` }}
                                                    />
                                                </View>
                                            }
                                        </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <ImageBackground
                            source={require('../../assets/card_gradient.png')}
                            style={[sx({
                                

                                flexDirection: ['column'],
                                justifyContent: 'space-between',
                                alignItems: ['center'],
                                flexWrap: 'wrap',
                                paddingTop: showAuthor ? 0 : 2,
                                minHeight: [30, 30, 40],
                                padding: 10,
                            }),
                            Platform.select({
                                native: sx({
                                    minHeight: 30,
                                }),
                            })
                            ]}
                        >
                            <View
                            style={{
                                alignSelf:'flex-end',
                                maxWidth: 110,
                                marginRight: 30,
                            }}
                            >
                            {
                                billing && classroomCheck?.isAvailableForPurchase ?
                                    <Button
                                        onPress={() => handleClassroomToBuy()}
                                        variantType='contained'
                                        startIcon={
                                            <Icon name='cart'
                                                width={18}
                                                height={18}
                                                fill={['$secondary']}
                                            />
                                        }
                                        startIconStyle={{
                                            marginRight: 0
                                        }}
                                        style={{ height: 30, borderRadius: 10, paddingHorizontal: 10, width: 110 }}
                                    >
                                        <I18Text style={sx({ color:'$white' })} >Buy now</I18Text>
                                    </Button> : <></>
                            }
                            </View>
                            <View
                            style={sx({
                                flex: 1,
                                width: '100%',
                                flexDirection: ['row'],
                                alignItems: ['center'],
                                justifyContent:'space-between',
                            })}
                            >
                            <View
                                style={sx({
                                    flex: 1,
                                    flexDirection: ['row'],
                                    alignItems: ['center'],
                                    flexWrap: 'wrap',
                                })}
                            >
                                <Text
                                    numberOfLines={2}
                                    ellipsizeMode="tail"
                                    lineBreakMode="clip"
                                    style={sx({
                                        fontWeight: '600',
                                        fontSize: [10, 14],
                                        lineHeight: [17, 21],
                                        flexShrink: 1,
                                    })}
                                >
                                    {name + (additionalInfo?.data?.ref_planner_id ? ` - ${additionalInfo?.data?.ref_planner_id}` : '')}
                                </Text>
                                {centerloading ? <Skeleton /> : <Text>{center ?? ''}</Text>}
                            </View>
                            <View
                            style={sx({
                                flex: 1,
                                flexDirection: ['column'],
                                alignItems: ['flex-end'],
                                justifyContent:'flex-end',
                            })}
                            >
                                {showAuthor && (
                                    <Link
                                        isNextLink={false}
                                        disabled={Platform.OS !== 'web'}
                                        style={sx({
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            minWidth: [100, 120],
                                            height: 40, 
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 1,
                                            },
                                            shadowOpacity:0.32,
                                            shadowRadius: 4.55,
                                            borderWidth: 1,
                                            borderColor: '$white',
                                            elevation:1
                                        })}
                                        containerStyle={{
                                            flexDirection:'row',
                                            justifyContent:'flex-end',
                                        }}
                                        href={`/u/${moderator?.username}`}
                                    >
                                        <View style={sx({
                                           display:'flex',flexDirection:'row',justifyContent:'space-between',
                                        })}>
                                        <Image
                                            resizeMode='contain'
                                            source={require('../../assets/LN-E.png')}
                                            style={sx({
                                                height: 30,
                                                width: 30,
                                                borderRadius: 15,
                                            })}
                                        />
                                        <Text
                                            numberOfLines={1}
                                            ellipsizeMode="head"
                                            style={sx({
                                                fontSize: [10, 12],
                                                color: '$fontGrey',
                                                flexShrink: 1,  // Allows text to shrink if necessary
                                                ...authorStyle,
                                                top:10
                                            })}
                                        >
                                            {author && hideMoreText(author, 15)}
                                        </Text>
                                        </View>
                                    </Link>
                                )}
                            </View>
                            </View>
                            {moduleLoading ? <Skeleton 
                            style={{
                                    width: 100,
                                    height: 20,
                                    marginRight: 10,
                                    marginTop: 10
                                }}
                            /> :
                                <ProgressBar
                                    label={
                                        <I18Text
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            My Progress
                                        </I18Text>
                                    }
                                    total={overAllProgress.totalRecord}
                                    covered={overAllProgress.totalCompleted}
                                    progressColor='#198038'
                                    backgroundColor='#AAA'
                                    percentageColor='#198038'
                                    showAnalytics={false}
                                    showProgressInPercent
                                    thickness={10}
                                    roundSize={20}
                                    decimalNumber={2}
                                    style={{
                                        selfAlign: 'center',
                                        // width: 500,
                                    }}
                                />}
                        </ImageBackground>
                    </ImageBackground>
                </MotiPressable>
                {openAddRemarkModal ? (
                    <Dialog
                        modalVisible={openAddRemarkModal}
                        onCloseModal={() => {
                            setRejectRemarkModal(false);
                        }}
                        styles={sx({
                            borderRadius: 5,
                            minWidth: [350, 500],
                            maxWidth: [350, 500],
                        })}
                    >
                        <DialogTitle styleContainer={{ paddingTop: '2%', paddingLeft: '2%' }}>
                            Add remark (optional)
                        </DialogTitle>
                        <View
                            style={{
                                padding: '2%',
                            }}
                        >
                            <TextArea
                                numberOfLines={3}
                                onChangeText={(value) => {
                                    setRemarkValue(value);
                                }}
                            />
                        </View>
                        <DialogAction>
                            <Button
                                onPress={() => {
                                    Alert({
                                        buttons: [
                                            {
                                                name: 'Cancel',
                                                onPress: () => {
                                                    setRejectRemarkModal(false);
                                                }
                                            },
                                            {
                                                name: 'Okay',
                                                onPress: () => {
                                                    rejectRequest({
                                                        data: {
                                                            status: 1,
                                                            // memberId: item?.memberId,
                                                            training: additionalInfo?.data?._id,
                                                            user: user?._id,
                                                            remarks: remarkValue,
                                                        },
                                                    });
                                                    setRejectRemarkModal(false);
                                                    setRemarkValue('');
                                                },
                                            },
                                        ],
                                        message: 'Are you sure you wish to delete this member?',
                                        title: 'Delete Member',
                                        type: 'warn',
                                    });
                                }}
                            >
                                <I18Text style={[sx({ color: '#fff' })]}>Submit</I18Text>
                            </Button>
                        </DialogAction>
                    </Dialog>
                ) : null}
            </View>
        );
    }
)({});